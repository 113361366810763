.site-layout-background {
  background: #fff;
}

.ant-layout-sider-trigger {
  background: #fff;
}

.ant-layout-sider-trigger > .anticon {
  color: #29c17e;
}
